import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './App.css';
import './scss/App.scss';
import { LicenseInfo } from "@mui/x-license-pro";
import PageNotFound from './components/PageNotFound';
import HomeLayout from './layouts/HomeLayout';
import LoginLayout from "./layouts/LoginLayout";
import { Login } from './pages/login/Login';
import { CustomRoutes } from "./pages/CustomRoutes";
import { CustomerAppointmentConfirmation } from "./pages/CustomerAppointmentConfirmation";

LicenseInfo.setLicenseKey(
  "db3778bfd01704f00cd67f803e1af331Tz04ODM1NixFPTE3NDQ1NzkzMzUwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    element: <LoginLayout />,
    children: [
      { path: "/", element: <Login /> },
      { path: "/login", element: <Login /> }
    ],
  }
  ,
  {
    path: "/",
    errorElement: <PageNotFound />,
    element: <HomeLayout />,
    children: [
      ...CustomRoutes
    ],
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
