import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomerScheduleAppointmentService } from "../common/services/customer-schedule-appointment/CustomerScheduleAppointmentService";
import { TextArea } from "../components/controls/TextArea";
import Loader from "../components/Loader";
import { CustomerAppointmentScheduleModel } from "../models/CustomerAppointmentScheduleModel";
import { HttpResponseType } from "../models/IHCResponseModel";
import { RootState } from "../store/store";
import { publicIpv4 } from "public-ip";
import { Button } from "@mui/material";

export type customerArrivalInfo = {
  customerAppointmentNote: string;
};
export const CustomerArrivalInformation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const timeWindowId = params.windowid;
  const tenantId = useSelector((state: RootState) => state.login.tenantId);
  const projectId = useSelector((state: RootState) => state.login.projectId);
  const referenceCode = useSelector((state: RootState) => state.login.referenceCode);
  const facilityId = useSelector((state: RootState) => state.login.facilityId);
  const serviceContactName = useSelector((state: RootState) => state.customer.serviceContactName);
  const arrivalInformationForm = useForm<customerArrivalInfo>();
  const [customerAppointmentSchedule, setCustomerAppointmentSchedule] =
    useState<CustomerAppointmentScheduleModel>({
      referenceCode: referenceCode,
      facilityId: facilityId,
      tenantId: tenantId,
      projectId: projectId,
      timeWindowId: timeWindowId,
      bookedBy: `${serviceContactName}`,
      ipAddress: "",
    });

  useEffect(() => {
    (async () => {
      const localIpAddress = await publicIpv4();
      setCustomerAppointmentSchedule((preState) => ({
        ...preState,
        ipAddress: localIpAddress,
      }));
    })();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
 
  const submitHandler = async () => {
    setIsLoading(true);    
    const appointmentModel = customerAppointmentSchedule;    
    appointmentModel.customerAppointmentNote= arrivalInformationForm.getValues("customerAppointmentNote");
    bookCustomerAppointment(appointmentModel);
  };

  const skipButtonHandler = async () => {
    setIsLoading(true);    
    bookCustomerAppointment(customerAppointmentSchedule);
  };

  const bookCustomerAppointment = async (appointmentModel: CustomerAppointmentScheduleModel) => {
    await CustomerScheduleAppointmentService.bookCustomerAppointmentScheduleAsync(appointmentModel)
      .then(async (response) => {
        if (response.status !== HttpResponseType.Success) {
          toast(response.message);
          navigate(`/login`);
          return;
        }
        setIsLoading(false);
        toast(response.message);
      })
      .catch(async (error) => {
        setIsLoading(false);
        toast(error);
      });
    navigate(`/customer-appointment-confirmation`);
  };
  const [textareaValue, setTextareaValue] = useState('');

  const handleTextareaChange = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(event.target.value);
  };
  return (
    <Fragment>
      <Loader isLoading={isLoading}>
        <div className="">
        <div className="container">
          <div className="col-sm-7 mx-auto shadow-box-1">
            <p className="appointment-heading">Arrival Info</p>
            <p className="text-center">
              Provide essential info for the field technician's<br></br> arrival
              at your location
            </p>
            <TextArea
              controlkey="customerNote"
              label=""
              placeholder="Add a note"
              type="text"
              rows={5}
              controlregister={arrivalInformationForm.register(
                "customerAppointmentNote",
                {
                  onChange: (e) => {
                    handleTextareaChange(e);
                  },
                }
              )
            }
            />
            <div className="btns-wrapper">
              <Button
                className="primary-contained-button ms-3"
                type="submit"
                onClick={skipButtonHandler}
                disabled={isLoading}
                variant="contained"
              >
                Skip
              </Button>
              <Button
                className="primary-contained-button"
                type="submit"
                disabled={
                  !textareaValue ||  !arrivalInformationForm.formState.isDirty || isLoading
                }
                onClick={submitHandler}
                variant="contained"
              >
                Next
              </Button>
            </div>
          </div>
        </div>
        </div>
      </Loader>
    </Fragment>
  );
};
