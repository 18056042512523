import React from "react";

type Props = {
  children: string | JSX.Element | JSX.Element[];
  isLoading: boolean;
};

const Loader = (props: Props) => {
  return (
    <React.Fragment>
      {props.children}
      {props.isLoading && (
        <div className={`${props.isLoading ? "loading" : ""}`}>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Loader;