import { Identity, CustomerLoginData } from "../../models/login/AuthenticationResponse";
import jwt_decode from 'jwt-decode';
const storagePrefix = 'customer_';

const storage = {

    getToken: () => {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}accesstoken`) as string);
    },
    getTokenExpirationTime: () => {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}tokenExpiration`) as string);
    },

    setToken: (token: string) => {
        window.localStorage.setItem(`${storagePrefix}accesstoken`, JSON.stringify(token));
    },

    setTokenExpiration: (expiresIn: number) => {
        window.localStorage.setItem(`${storagePrefix}tokenExpiration`, JSON.stringify(Date.now() + expiresIn * 1000));
    },
    clearToken: () => {
        window.localStorage.removeItem(`${storagePrefix}accesstoken`);
        window.localStorage.removeItem(`${storagePrefix}tokenExpiration`);
        window.localStorage.removeItem(`${storagePrefix}identity`);
    },

    setUserSession: (identity: any) => window.localStorage.setItem(`${storagePrefix}identity`, JSON.stringify(identity)),
    getUserSession: (): Identity => {
        const identity = window.localStorage.getItem(`${storagePrefix}identity`);
        return JSON.parse(identity!);
    },
    clearUserSession: () => window.localStorage.removeItem(`${storagePrefix}identity`),
    decodeToken: (token: string) => {
        let customerData = {} as CustomerLoginData;
        if (token) {
            var decoded = jwt_decode<object>(token);
            let tokenJSON = JSON.parse(JSON.stringify(decoded));
            for (let prop in tokenJSON) {
                if (prop === 'customerInformation') {
                    customerData = tokenJSON[prop];
                }
            }
        }
        return customerData
    }
};

export default storage;
