import { axios } from "../common/utils/axios"

const BaseMicroserviceURL = {

    ClientAPI_URL: process.env.REACT_APP_CLIENT_API_URL
}

export const SetClientBaseMicroserviceURL = () => {
    axios.defaults.url = BaseMicroserviceURL.ClientAPI_URL;
    axios.defaults.baseURL = BaseMicroserviceURL.ClientAPI_URL;
}