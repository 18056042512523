import { useState } from "react";

export type ListGridItem = {
    id: string;
    timeWindow: string;
};

export type ListProps<T> = {
    items: T[];
    onItemClick: (id: string) => void;
}

export const ListGrid = <T extends ListGridItem>({ items, onItemClick }: ListProps<T>) => {
    const [selectedTimeWindow, setSelectedTimeWindow] = useState<string | null>();
    const handleItemClick = (id: string) => {
        setSelectedTimeWindow(id);
        onItemClick(id);
    }
    return (
        <ul className="list time-select-list">
            {items.map((item) => (
                <li
                    key={item.id}
                    className={`list-item ${ selectedTimeWindow === item.id  ? 'selected-item': ''}`}
                    onClick={() => handleItemClick(item.id)}
                >
                    <span>{item.timeWindow}</span>

                </li>
            ))}
        </ul>
    );

}