import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomerScheduleAppointmentService } from "../common/services/customer-schedule-appointment/CustomerScheduleAppointmentService";
import { AppFunctions } from "../helpers/AppFunctions";
import { logout } from "../store/AuthStore";
import { RootState } from "../store/store";

type BookedTimeWindow = {
  bookingDate?: string;
  startTime?: string;
  endTime?: string;
};

export const CustomerAppointmentConfirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const faciltyId = useSelector((state: RootState) => state.login.facilityId);
  const [bookedTimeWindow, setBookedTimeWindow] = useState<BookedTimeWindow>();

  useEffect(() => {
    (async () => {
      await CustomerScheduleAppointmentService.getCustomerAppointmentScheduleAsync(
        faciltyId!
      ).then(async (response) => {
        if (!AppFunctions.IsNullOrUndefined(response)) {
          setBookedTimeWindow({
            bookingDate: moment(response.startTime)
              .parseZone()
              .local()
              .format("dddd , MMMM D YYYY z"),
            startTime: moment(response.startTime)
              .parseZone()
              .local()
              .format("hh:mm a"),
            endTime: moment(response.endTime)
              .parseZone()
              .local()
              .format("hh:mm a"),
          });
          return;
        }
        await dispatch(logout());
        navigate(`/login`);
      });
    })().catch(async (error) => {
      toast.error(error, { theme: "colored" });
      await dispatch(logout());
      navigate(`/login`);
    });
  }, []);

  return (
    <Fragment>
      <div className="content-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <p className="appointment-heading">
                Time Window Confirmed
              </p>
              <p>
                Your selected window is{" "}
                <b>
                  {bookedTimeWindow?.bookingDate} , from
                  <br></br>
                  {bookedTimeWindow?.startTime} to {bookedTimeWindow?.endTime}
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
