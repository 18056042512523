import { Constants } from "../constants/Constants";

export class ParserHelper {

    public static parsePhone(value?: string, maxLength?: number): string | undefined {
        let phoneNumber: string = '';

        const isNumberMatches = value?.match(Constants.RegexDigits);
        if (!isNumberMatches)
            return value;

        let phone = isNumberMatches.join('');
        maxLength = maxLength ?? phone.length;
        phone = phone.substring(0, maxLength).toString();

        switch (true) {
            case phone.length === 7:
                phoneNumber = `(${phone.substring(0, 3)})-${phone.substring(3)}`;
                break;
            case phone.length === 10:
                phoneNumber = `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`;
                break;
            case phone.length > 10:
                phoneNumber = `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
                break;

            default:
                return value;
        }

        return phoneNumber;
    }

    public static parseNumber(value: string, formatDecimals: number): string | undefined {
        const cleanValue = value.replace(/[^0-9.]/g, '');
        const isNumberValMatches = cleanValue.match(/^(\d+\.\d+|\.\d+)$/);

        if (!isNumberValMatches)
            return '';

        return String(parseFloat(cleanValue).toFixed(formatDecimals))
    }

    public static parseNumberOnly(value: string): string | undefined {
        return value.replace(/[^\d]/g, '');
    }

    public static formatRefernceCode = (inputValue: string, maxLength: number): string => {
        const formattedValue = inputValue.toUpperCase().replace(/-/g, '').substring(0, maxLength);
        let result = '';
        for (let i = 0; i < formattedValue.length; i++) {
            if (i > 0 && i % 3 === 0) {
                result += '-'
            }
            result += formattedValue[i]
        }
        return result;
    }

}