import React from "react";

const PageNotFound = () => {
  const title = "An error occoored!";
  const message = "404 - Page Not Found (Something went wrong!)";
  return (
    <React.Fragment>
      <div className="container">
        <main>
          <h1>{title}</h1>
          <p>{message}</p>
        </main>
      </div>
    </React.Fragment>
  );
};

export default PageNotFound;
