import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from "react-redux";
import {store,persistor} from "./store/store";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <App />
    </PersistGate>
        <ToastContainer position="top-center" autoClose={2000}
            hideProgressBar={true}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={false}
            className={'cancel-toast'}
            theme={"dark"} />
  </Provider>
);
