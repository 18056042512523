import Switch from '@mui/material/Switch';
import { Fragment, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { IControlProps } from '../../models/IControlProps';

export type ToggleButtonProps = IControlProps & {
    form: any,
    labelClassName: string
}

export default function ToggleButton(props: ToggleButtonProps) {
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false)
    }, [props.isrequired])
    return (
        <Fragment>

            <Controller
                name={props.controlkey}
                control={props.form.control}
                render={({ field }) => (
                    <Switch {...field} checked={props.form.getValues(props.controlkey)} />
                )}
            />

            {props.label != "" && <label htmlFor={props.controlkey}>
                <span className={props.labelClassName}>{props.label}</span>
                {isRequired &&
                    <i className={`required-icon`}></i>
                }
            </label>}
        </Fragment>
    );
}