import { SetClientBaseMicroserviceURL } from "../../../config";
import { IAuthenticationResponse } from "../../../models/login/AuthenticationResponse";
import { axios } from "../../utils/axios";

const loginAsync = async (referenceCode: string): Promise<IAuthenticationResponse> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/CustomerPortal/customerlogin`, {
        referenceCode
    });
}

export const AuthService
    = {
    loginAsync: loginAsync
}