import { SetClientBaseMicroserviceURL } from "../../../config";
import { AppointmentScheduleModel } from "../../../models/AppointmentScheduleModel";
import { CustomerAppointmentScheduleModel } from "../../../models/CustomerAppointmentScheduleModel";
import { IHCResponseModel } from "../../../models/IHCResponseModel";
import { TimeWindowModel } from "../../../models/TimeWindowModel";
import { axios } from "../../utils/axios";

const getScheduleAppointmentAvailableDatesAsync = async (projectId: string, faciltyId: string): Promise<TimeWindowModel[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/CustomerScheduling/getscheduleappointmentavailabledates/${projectId}/${faciltyId}`);
}

const bookCustomerAppointmentScheduleAsync = async (customerAppointmentScheduleModel: CustomerAppointmentScheduleModel): Promise<IHCResponseModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/CustomerScheduling/bookcustomerappointmentschedule`, customerAppointmentScheduleModel);
}

const getCustomerAppointmentScheduleAsync = async (facilityId: string): Promise<AppointmentScheduleModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/CustomerScheduling/getcustomerappointmentschedule/${facilityId}`);
}

export const CustomerScheduleAppointmentService
    = {
    getScheduleAppointmentAvailableDatesAsync: getScheduleAppointmentAvailableDatesAsync,
    bookCustomerAppointmentScheduleAsync: bookCustomerAppointmentScheduleAsync,
    getCustomerAppointmentScheduleAsync: getCustomerAppointmentScheduleAsync,
}