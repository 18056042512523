import Axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import storage from "./storage";
import { IHttpException } from "../../models/exceptions/IHttpException";

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
    config.headers = config.headers ?? {};
    const token = storage.getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = "application/json";
    return config;
}

export const axios = Axios.create({
    baseURL: process.env.REACT_APP_USER_API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        const err = error as Error | AxiosError;

        if (!Axios.isAxiosError(err)) {
            return err;
        }

        const data = error.response?.data ?? error.message;
        throw {
            message: data.error ?? data,
            details: data.stackTrace,
        } as IHttpException;
    }
);
