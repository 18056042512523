import { createSlice } from "@reduxjs/toolkit";
import { CustomerInformationSchemaType } from "../pages/customer-information/CustomerInformationSchema";

export const CustomerStoreSlice = createSlice({
    name: "customer",
    initialState: {} as CustomerInformationSchemaType,
    reducers: {
        customerInformation: (state: CustomerInformationSchemaType, action) => {
            state.referenceCode = action.payload.referenceCode;
            state.serviceContactName = action.payload.serviceContactName;
            state.email = action.payload.email;
            state.primaryPhone = action.payload.primaryPhone;
            state.canReceiveTextMessage = action.payload.canReceiveTextMessage;
            state.isEasyAccessToWaterMeter = action.payload.isEasyAccessToWaterMeter;
            state.isFinishedBasement = action.payload.isFinishedBasement;
            state.isLocationSingleFamilyHome = action.payload.isLocationSingleFamilyHome;

        }
    },
});

export const { customerInformation } = CustomerStoreSlice.actions;