import { z } from "zod";
import { Constants } from "../../constants/Constants";

export const CustomerInformationSchema = z.object({
    referenceCode: z.string(),
    serviceContactName: z.string().min(1, "Service contact name is required").regex(Constants.RegexName, "First name should contain alphabets only"),
    primaryPhone: z.string().min(1, "Number is required").regex(Constants.RegexPhone, "Number is not valid"),
    email: z.string().email("Invalid email"),
    canReceiveTextMessage: z.boolean().optional(),
    isFinishedBasement: z.boolean().optional(),
    isEasyAccessToWaterMeter: z.boolean().optional(),
    isLocationSingleFamilyHome: z.boolean().optional(),
    customerAppointmentNote:z.string().optional().nullable()
})

export type CustomerInformationSchemaType = z.infer<typeof CustomerInformationSchema>;