import storage from "../common/utils/storage";
import { initialState, IAuthenticationResponse, Identity } from "../models/login/AuthenticationResponse";
import { createSlice } from "@reduxjs/toolkit";

export const AuthStoreSlice = createSlice({
    name: "login",
    initialState: initialState,
    reducers: {
        userLoginInfo: (state: IAuthenticationResponse, action) => {
            const authToken = action.payload.accessToken ?? action.payload;
            const hasAccessTokenReceiving = typeof action.payload === 'string';
            var userdata = storage.decodeToken(authToken);
            state.accessToken = authToken;
            state.facilityName = userdata.FacilityName;
            state.email = userdata.Email;
            state.referenceCode = userdata.ReferenceCode;
            state.tenantId = userdata.TenantId;
            state.facilityId = userdata.FacilityId;
            state.projectId = userdata.ProjectId;
            state.expiersIn = !hasAccessTokenReceiving ? action.payload.expiersIn : storage.getTokenExpirationTime();
            storage.setToken(state.accessToken!);
            storage.setTokenExpiration(state.expiersIn!);
            storage.setUserSession(state as Identity);
        },
        logout: (state: IAuthenticationResponse) => {
            state = { ...initialState };
            storage.clearToken();
            storage.clearUserSession();
        }
    },
});

export const { userLoginInfo, logout } = AuthStoreSlice.actions;