import { PickerSelectionState } from "@mui/x-date-pickers/internals";
import moment, { Moment } from "moment";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomerScheduleAppointmentService } from "../../common/services/customer-schedule-appointment/CustomerScheduleAppointmentService";
import { DateCalendarField } from "../../components/controls/DateCalendarField";
import { ListGrid, ListGridItem } from "../../components/ListGrid";
import Loader from "../../components/Loader";
import { TimeWindowModel } from "../../models/TimeWindowModel";
import { RootState } from "../../store/store";
import { Button } from "@mui/material";

export const CustomerAppointmentSchedule = () => {
  const [showData, setShowData] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const projectId = useSelector((state: RootState) => state.login.projectId);
  const faciltyId = useSelector((state: RootState) => state.login.facilityId);
  const [timeWindowList, setTimeWindowList] = useState<ListGridItem[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>();
  const [selectedWindow, setSelectedWindow] = useState<string>();
  const [minDate, setMinDate] = useState<Moment>();
  const [isWindowSelected, setIsWindowSeleted] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await getScheduleAppointmentAvailableDates();
    })();
  }, []);

  const [availableDates, setAvailableDates] = useState<Moment[]>();
  const [availableTimeWindowList, setAvailableTimeWindowList] = useState<TimeWindowModel[]>();

  const getScheduleAppointmentAvailableDates = async () => {
    setIsLoading(true);
    await CustomerScheduleAppointmentService.
      getScheduleAppointmentAvailableDatesAsync(projectId!, faciltyId!).then(response => {
        setIsLoading(false);
        setError(false);
        setShowData(true);
        const momentArray = response.map((x) => moment(moment(x.endTime).parseZone().local()
          .format("YYYY-MM-DD")
        ));
        setAvailableDates(momentArray);
        setMinDate(momentArray[0]);
        setAvailableTimeWindowList(response);
      }, error => {
        setIsLoading(false);
        setError(true);
        setShowData(true);

      })
  }

  const onDateChange = (value?: Moment, selectionState?: PickerSelectionState) => {
    setSelectedDate(moment(value).parseZone().local().format('dddd , MMMM D YYYY z'));
    const availableTimeWindowByDate = availableTimeWindowList?.
      filter(x => moment(x.startTime).parseZone().local().isSame(value, 'day')).map(x => {
        return {
          id: x.id,
          timeWindow: moment(x.startTime).parseZone().local().format('hh:mm a') + "-" + moment(x.endTime).parseZone().local().format('hh:mm a')
        } as ListGridItem;
      });

    setTimeWindowList(availableTimeWindowByDate!);
  }

  const onListItemClick = (timeWindowId: string) => {
    setSelectedWindow(timeWindowId);
    setIsWindowSeleted(true);
  }
  const handleSchedule = async () => {
    navigate(`/customer-arrival-information/${selectedWindow}`);
  }

  return (
    <Fragment>
      <Loader isLoading={isLoading}>
        <div className="container customer-appointment-page ">
          <div className="col-sm-7 mx-auto shadow-box-1">
            {showData && error && <div className="container">
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6 text-center">
                  <p className="appointment-unavailable-container">Sorry, there are currently no available time
                    slots for scheduling appointments at this facility reference number.
                    Our team is working diligently to open up new time blocks.
                    Please check back later or contact our customer care team at (800) 315-4305
                    for further assistance.</p>
                </div>
                <div className="col-sm-3"></div>
              </div>
            </div>}
            {showData && !error &&

              <div className="">
                <div >
                  <div className="row">
                    <div className="col-sm-12">
                      <p className="appointment-heading"> Select a date and time window</p>
                    </div>
                    <div className="col-sm-7 text-right">
                      <DateCalendarField
                        views={["day"]}
                        minDate={minDate}
                        dates={availableDates!}
                        onChange={(value, selectonState) => onDateChange(value!, selectonState)}
                      ></DateCalendarField>
                      <span></span>
                    </div>
                    <div className="col-sm-5">
                      <p className="mb-2">{selectedDate}</p>
                      <ListGrid items={timeWindowList!} onItemClick={onListItemClick}></ListGrid>
                    </div>
                  </div>
                  <p className="text-center">Please note that a field technician will arrive at your facility at any time during your selected time window.</p>

                  <div className="col-sm-12 text-center">
                    <Button
                      className="primary-contained-button"
                      type="submit"
                      disabled={!isWindowSelected} onClick={handleSchedule}
                      variant="contained"
                    >
                      Schedule
                    </Button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </Loader>
    </Fragment>
  );
}

