import { IHttpException } from "../exceptions/IHttpException";

export interface IAuthenticationResponse extends IHttpException {
    referenceCode: string | null;
    facilityId: string | null;
    accessToken: string | null;
    facilityName: string | null;
    expiersIn: number | null;
    email: string | null;
    tenantId: string | null;
    projectId: string | null;
}

export const initialState: IAuthenticationResponse = {
    referenceCode: null,
    facilityId: null,
    accessToken: null,
    facilityName: null,
    expiersIn: null,
    email: null,
    tenantId: null,
    projectId: null,
    success: false,
    statusCode: 0,
    error: "",
    code: "",
    message: "",
};

export type Identity = {
    facilityName: string | null;
    expiersIn: number | null;
    email: string | null;
};

export type CustomerLoginData = {
    ReferenceCode: string | null;
    FacilityId: string | null;
    FacilityName: string | null;
    Email: string | null;
    TenantId: string;
    ProjectId: string;
}
