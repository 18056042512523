import { Constants } from "../../constants/Constants";
import { IControlProps } from "../../models/IControlProps";
import { Fragment, useEffect, useState } from "react";

export const InputField = ((props: IControlProps) => {
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false)
    }, [props.isrequired])

    return (
        <Fragment>
            <div className={props.type !== Constants.ControlTypeHidden ? `form-group` : ``}>
                {props.type !== Constants.ControlTypeHidden && <label htmlFor={props.controlkey}>
                    <span>{props.label}</span>
                    {isRequired &&
                        <i className={`required-icon`}></i>
                    }
                </label>}
                <div className="input-container">
                    <input
                        className="form-control form-input"
                        id={props.controlkey}
                        disabled={props.readOnly}
                        type={props.type || Constants.ControlTypeText}
                        placeholder={props.placeholder}
                        readOnly={props.readOnly ?? false}
                        value={props.value}
                        {...(props.controlregister ?? {})}
                    />
                    {
                        props.error ? (<span className="error-text">{props.error}</span>) : null
                    }
                </div>
            </div>
        </Fragment>
    );
});
