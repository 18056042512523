export interface IHCResponseModel {
    id: string;
    status: HttpResponseType;
    statusCode?: HttpResponseStatusCode;
    message: string;
    validateResultResponse: ValidateResultResponse
}

type ValidateResultResponse = {
    isSuccess: boolean,
    Errors: string[]
}

export enum HttpResponseType {
    Success,
    Failed
}

export enum HttpResponseStatusCode {
    OK = 200,
    Created = 201,
    Accepted = 202,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    InternalServerError = 500
}