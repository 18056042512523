import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { AuthService } from "../../common/services/login/AuthService";
import { HttpStatusCode } from "axios";
import { userLoginInfo } from "../../store/AuthStore";
import Loader from "../../components/Loader";
import { zodResolver } from "@hookform/resolvers/zod";
import { PartitionedInputField } from "../../components/controls/PartitonedInputField";
import { ParserHelper } from "../../helpers/ParserHelper";
import { Button } from "@mui/material";

const loginSchema = z.object({
  refrenceCode: z
    .string()
    .min(1, "Please enter valid reference code")
    .max(11, "Please enter valid reference code"),
});

type LoginSchemaType = z.infer<typeof loginSchema>;

export const Login = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isSubmitting },
  } = useForm<LoginSchemaType>({
    resolver: zodResolver(loginSchema),
    mode: "onChange",
  });

  const PPEPolicyPdf = "/pdfs/PPEPolicy.pdf";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const onSubmitHandler = async (data: LoginSchemaType) => {
    setIsLoading(true);
    await AuthService.loginAsync(data.refrenceCode).then(
      async (response) => {
        setIsLoading(false);
        if (response.statusCode === HttpStatusCode.Unauthorized) {
          setError(response.message);
          return;
        }
        dispatch(userLoginInfo(response));
        setError("");
        navigate("/customer-information");
      },
      (err) => {
        setIsLoading(false);
        setError(err.message);
      }
    );
  };

  return (
    <Fragment>
      <Loader isLoading={isLoading}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="login-screen-wrapper">
            <div className="container">
              <h1 className="title-one">Schedule your appointment</h1>
              <h4>Enter your reference code below e.g.(abcdfghij)</h4>
              <div className="reference-code-wrapper">
                <PartitionedInputField
                  className="partitioned-input"
                  controlkey="refrenceCode"
                  label=""
                  type="text"
                  placeholder="--- --- ---"
                  maxLength={11}
                  controlregister={register("refrenceCode", {
                    onChange: (e) =>
                      setValue(
                        "refrenceCode",
                        ParserHelper.formatRefernceCode(e.target.value, 11)!
                      ),
                  })}
                  error={errors.refrenceCode?.message as string}
                />
                {error != "" && <p className="error-text">{error}</p>}
              </div>
              <p className="policy-text">
                Please read our  {" "}
                <a href={PPEPolicyPdf} target="_blank">
                  PPE Policy
                </a>  {" "}
                before scheduling
              </p>
              <Button
                className="primary-contained-button"
                type="submit"
                disabled={!isValid || isSubmitting}
                variant="contained"
              >
                Next
              </Button>
              <p className="help-bottom-text">
                <b> Need help logging in? </b> Please call our Customer Care
                Team at <a>(800) 315-4305</a>
              </p>
            </div>
          </div>
        </form>
      </Loader>
    </Fragment>
  );
};
